// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import logo from '../assets/yxc-banner-logo.png'; // Update with your logo path

const Footer = () => {
  return (
    <motion.footer
      className="bg-white text-gray-600 py-12 border-t border-gray-200"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6">
            <img src={logo} alt="YouthXConnect Logo" className="h-10 w-auto mb-4" />
            <p className="text-gray-500">Empowering Youth <br></br>for a Better Tomorrow</p>
          </div>
          <div className="w-full md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul>
              <li className="mb-2">
                <Link to="/" className="hover:text-gray-900 transition">
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="hover:text-gray-900 transition">
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/events" className="hover:text-gray-900 transition">
                  Events
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold mb-4">Policies</h3>
            <ul>
              <li className="mb-2">
                <Link to="/privacy" className="hover:text-gray-900 transition">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/terms" className="hover:text-gray-900 transition">
                  Terms of Service
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/refund" className="hover:text-gray-900 transition">
                  Refund Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/payment" className="hover:text-gray-900 transition">
                  Payment Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" className="hover:text-gray-900 transition">
                <FaFacebookF />
              </a>
              <a href="https://twitter.com" className="hover:text-gray-900 transition">
                <FaTwitter />
              </a>
              <a href="https://linkedin.com" className="hover:text-gray-900 transition">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-500">
          &copy; {new Date().getFullYear()} YouthXConnect. All rights reserved.
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;